import React from 'react'

type Props = {}

const FilterSvg = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <g>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.74451 18.3337L11.7248 16.4603V11.5506L17.9662 5.22079C18.2014 4.98362 18.3334 4.6581 18.3334 4.31722V2.93161C18.3334 2.23257 17.7849 1.66699 17.108 1.66699H2.89216C2.2153 1.66699 1.66675 2.23257 1.66675 2.93161V4.34891C1.66675 4.67058 1.78483 4.97978 1.99774 5.21407L7.74451 11.5506V18.3337Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  )
}

export default FilterSvg;
