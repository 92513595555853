
export const sizes = {
  xSmall: '0.75rem',     // 12px
  small: '0.875rem',     // 14x
  normal: '1rem',        // 16px
  middle: '1.125rem',    // 18px
  xMiddle: '1.25rem',    // 20px
  large: '1.375rem',     // 22px
  xLarge: '1.5rem',      // 24px
  xxLarge: '1.75rem',    // 28px
  big: '2.125rem',       // 34px
  xBig: '2.25rem',       // 36px
  xxBig: '2.375rem',     // 38px
  

  12: '0.75rem',         // xSmall
  14: '0.875rem',        // small
  16: '1rem',            // normal
  18: '1.125rem',        // middle
  20: '1.25rem',         // xMiddle
  22: '1.375rem',        // large
  24: '1.5rem',          // xLarge
  28: '1.75rem',         // xxLarge
  34: '2.125rem',        // big
  36: '2.25rem',         // xBig
  38: '2.375rem',        // xxBig
}

export type typeSizes = typeof sizes;
