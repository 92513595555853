import React, { FC, memo, useEffect, useState } from 'react';
import { StyledWrapper } from './style';
import { FaAngleDoubleUp } from 'react-icons/fa';

interface IScrollToTopProps {};

const ScrollToTop: FC<IScrollToTopProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);

  const toTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    function onScroll() {
      if(window.scrollY > 800) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  if(!visible) return null;

  return (
    <StyledWrapper onClick={toTop}>
      <FaAngleDoubleUp/>
    </StyledWrapper>
  )
}

export default memo(ScrollToTop);
