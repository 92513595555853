import { initializeApp, FirebaseOptions, FirebaseApp } from "firebase/app";
import { Messaging, getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { payloadType, typeMessageHandlerFunction } from "./types";
import { logger } from "@/Services/Logger";


export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_F_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_F_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_F_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_F_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_F_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_F_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_F_MEASUREMENT_ID,
};

export interface ICustomFirebaseConfig extends FirebaseOptions {
  language: 'uz' | 'ru'
}

class Firebase {
  private app: FirebaseApp | null = null;
  private messaging: Messaging | null = null;
  protected initialized: boolean = false;
  
  private language: 'uz' | 'ru' = 'uz';
  private storageChannelBroadcast: BroadcastChannel | null = null;

  private messageHandlers: typeMessageHandlerFunction[] = [
    (payload) => {
      logger('FIREBASE_APP_ONMESSAGE: ', payload);
    }
  ];

  private configure({language, ...options}: ICustomFirebaseConfig) {
    this.app = initializeApp(options);
    this.messaging = getMessaging(this.app);
    this.language = language;
    this.initialized = true;
  }


  public async initialize(config: ICustomFirebaseConfig) {
    if (typeof document === 'undefined') {
      logger.warn('Firebase can be initialized only on client side.');
      return;
    }
    const isSwSupported = await isSupported();
    if(!isSwSupported) {
      logger.warn('Service worker could not work on this browser.');
      return;
    }

    this.configure(config);
    if (!this.app) return;
    this.getApplicationToken();
    this.initBroadcastChannel();

    // TODO should work after getting token with async await or Promise then
    setTimeout(this.initMessageHandling.bind(this), 3000);
  }

  public destroy() {
    this.closeBroadcasetChannel();
  }

  private getApplicationToken() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        getToken(this.messaging!, { vapidKey: process.env.NEXT_PUBLIC_WEB_PUSH_KEY_PAIR }).then((currentToken) => {
          if(currentToken) {
            // logger.log('token saving');
            localStorage.setItem('applicationToken', currentToken);
          } else {
            // NOTE: token berilmasa onMessage ishlamaydi
            logger.log('can not get token');
          }
        });
      } else {
        logger.log('Notification permission denied.');
        // NOTE: token berilmasa onMessage ishlamaydi
      }
    })
  }

  // NOTE: returns index of the handler in order to unsubscribe it;
  public subscribeMessageHandler(fn: typeMessageHandlerFunction): number {
    return this.messageHandlers.push(fn) - 1;
  }
  
  // NOTE: delete handler from list of handlers;
  public unSubscribeMessageHandler(index: number) {
    delete this.messageHandlers[index];
  }

  private initMessageHandling() {
    // NOTE: launch message handling
    const unsubscriber = onMessage(this.messaging!, (payload) => {
      this.messageHandlers.forEach((msgHandler) => {
        msgHandler && msgHandler(payload as payloadType);
      })
    });
  }

  private initBroadcastChannel() {
    this.storageChannelBroadcast = new BroadcastChannel('storageChannel');
    this.updateLanguageOnFcm(this.language);
  }

  private closeBroadcasetChannel() {
    this.storageChannelBroadcast?.close();
  }

  public updateLanguageOnFcm (lng: 'uz' | 'ru') {
    if(this.initialized) {
      this.language = lng;
      this.storageChannelBroadcast!.postMessage({ lng });
    }
  }

  private _clear_firebase_data() {
    if(typeof indexedDB !== 'undefined') {
      indexedDB.databases().then(dbs => dbs.forEach(db => indexedDB.deleteDatabase(db.name!)))
    }
  }
}

export const firebase_instance = new Firebase();

