export const colors = {
  lightPrimary: 'rgba(229,242,248,255)',
  primary: 'rgba(7,143,205,255)',
  primaryGradient: 'linear-gradient(192deg, #65C9F5 1.15%, #0077AC 100%)',
  darkerPrimary: 'rgba(77, 135, 155, 1)',
  darkestPrimary: '#002D42',
  darkPrimary: 'rgba(1,77,113,255)',
  snow: '#f9f9f9',
  whiteSmoke2: '#f3f3f3',
  categoryLi:'#414141',
  whiteSmoke: '#efefef',
  nero: '#151515',
  silver: '#c4c4c4',
  darkGray: '#9B9B9B',
  approxGrey: '#7D7D7D',
  approxWhisper: '#e7e7e7',
  grayishBlue: '#4A5568',
  suvaGrey: '#8B8B8B',
  gray99: '#FCFCFC',
  gray95: '#f2f2f2',
  gray75: '#bfbfbf',
  pink: '#C31162',
  
  orange: '#ff9800',
  green: '#31A85E',
  secondary: '#eee',
  secondary2: '#656565',
  primary2:"#0C88C6",
  success: 'green',
  error: 'red',
  warning: 'orange',
  danger: '#C31162',
  
  dark: '#000',
  dark100: '#111',
  dark200: '#222',
  dark300: '#333',
  dark400: '#444',
  dark500: '#555',
  dark600: '#666',
  dark700: '#777',
  dark800: '#888',
  dark900: '#999',
}

export type typeColors = typeof colors;