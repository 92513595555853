import React, { FC } from 'react';
import { Dialog, DialogContent, DialogProps } from '@mui/material';

interface IMuiModalProps extends DialogProps {}

// TODO: NEED TO IMPROVE

const MuiModal: FC<IMuiModalProps> = ({ children, open, ...rest }) => {
  return (
    <Dialog open={open} {...rest} fullWidth>
      <DialogContent>
        { children }
      </DialogContent>
    </Dialog>
  )
}

export default MuiModal;
