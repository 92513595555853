import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

export type NextRouterEventType = 'routeChangeStart' | 'beforeHistoryChange' | 'routeChangeComplete' | 'routeChangeError' | 'hashChangeStart' | 'hashChangeComplete';

export default function useRouterChange(): boolean {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      if (router.pathname === url || shallow) return;

      setLoading(true);
      if (url.includes('/item') || url.includes('/faq') || url.includes('/terms-and-conditions') || url.includes('/privacy-policy')) {
        window.scrollTo(0, 0);
      }
      return;
    };

    const handleRouteComplete = (url: string, { shallow }: { shallow: boolean }) => {
      // if(shallow) return;
      setLoading(false);
      return;
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteComplete);
    router.events.on('routeChangeError', handleRouteComplete);

    setLoading(false);
  }, []);

  return loading;
}
