import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const CameraSvg: FC<Omit<ICommonSvgProps, 'name'>> = (props) => {
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.638 7.10975V7.10975C17.265 7.10975 16.927 6.89475 16.768 6.55875C16.481 5.95075 16.116 5.17375 15.9 4.75075C15.581 4.12175 15.064 3.75575 14.348 3.75075C14.336 3.74975 9.664 3.74975 9.652 3.75075C8.936 3.75575 8.42 4.12175 8.1 4.75075C7.885 5.17375 7.52 5.95075 7.233 6.55875C7.074 6.89475 6.735 7.10975 6.363 7.10975V7.10975C4.367 7.10975 2.75 8.72675 2.75 10.7217V16.6578C2.75 18.6518 4.367 20.2698 6.363 20.2698H17.638C19.633 20.2698 21.25 18.6518 21.25 16.6578V10.7217C21.25 8.72675 19.633 7.10975 17.638 7.10975Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.82041 13.3343C8.81941 15.0873 10.2504 16.5213 12.0004 16.5203C13.7474 16.5183 15.1744 15.0903 15.1774 13.3413C15.1804 11.5853 13.7544 10.1543 12.0024 10.1523C10.2404 10.1503 8.80641 11.6053 8.82041 13.3343Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.9025 10.9951C16.8059 10.9852 16.7105 10.96 16.6036 10.9169C16.4847 10.8641 16.3818 10.7969 16.2823 10.6959C16.1035 10.5057 16 10.257 16 10.0005C16 9.86422 16.0279 9.72916 16.0823 9.60681C16.1368 9.48285 16.2014 9.37829 16.3182 9.26456C16.407 9.18719 16.498 9.12493 16.6224 9.07013C16.9913 8.92396 17.4301 9.0113 17.7043 9.28541C17.7865 9.36639 17.8557 9.46411 17.8941 9.54651L17.9169 9.60494C17.9721 9.72916 18 9.86422 18 10.0005C18 10.2622 17.8978 10.5043 17.7081 10.7058C17.5442 10.8708 17.3289 10.9723 17.0992 10.9951L17 11L16.9025 10.9951Z" fill="currentColor"/>
    </svg>
  )
}

export default CameraSvg;