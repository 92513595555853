import { styled } from "@mui/material/styles";

const StyledWrapper = styled('div')`
  position: fixed;
  z-index: 100;
  bottom: 2rem;
  right: 2rem;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${props => props.theme.customTheme.bg.primary};
  color: #fff;
  border: 2px solid transparent;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  &:hover {
    background-color: #fff;
    color: ${props => props.theme.customTheme.colors.primary};
    border-color: ${props => props.theme.customTheme.colors.primary};
  }

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 991.98px) {
    bottom: 6.5rem;
    right: 1.5rem;
  }
  
  @media (max-width: 767.98px) {
    right: 10px;
  }
`;

export {
  StyledWrapper,
};
