import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const PhoneSvg: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.45 18C15.3667 18 13.3083 17.5457 11.275 16.637C9.24167 15.7283 7.39167 14.441 5.725 12.775C4.05833 11.1083 2.771 9.25833 1.863 7.225C0.955 5.19167 0.500667 3.13333 0.5 1.05C0.5 0.75 0.6 0.5 0.8 0.3C1 0.0999999 1.25 0 1.55 0H5.6C5.83333 0 6.04167 0.0793332 6.225 0.238C6.40833 0.396667 6.51667 0.584 6.55 0.8L7.2 4.3C7.23333 4.56667 7.225 4.79167 7.175 4.975C7.125 5.15833 7.03333 5.31667 6.9 5.45L4.475 7.9C4.80833 8.51667 5.204 9.11233 5.662 9.687C6.12 10.2617 6.62433 10.816 7.175 11.35C7.69167 11.8667 8.23333 12.346 8.8 12.788C9.36667 13.23 9.96667 13.634 10.6 14L12.95 11.65C13.1 11.5 13.296 11.3873 13.538 11.312C13.78 11.2367 14.0173 11.216 14.25 11.25L17.7 11.95C17.9333 12.0167 18.125 12.1377 18.275 12.313C18.425 12.4883 18.5 12.684 18.5 12.9V16.95C18.5 17.25 18.4 17.5 18.2 17.7C18 17.9 17.75 18 17.45 18Z'
        stroke='currentcolor'
      />
    </svg>
  );
};

export default PhoneSvg;
