import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const EditSvg: FC<Omit<ICommonSvgProps, 'name'>> = (props) => {
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g>
      <g>
      <g>
      <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.0418 3.02025L8.16183 10.9003C7.86183 11.2003 7.56183 11.7903 7.50183 12.2203L7.07183 15.2303C6.91183 16.3203 7.68183 17.0803 8.77183 16.9303L11.7818 16.5003C12.2018 16.4403 12.7918 16.1403 13.1018 15.8403L20.9818 7.96025C22.3418 6.60025 22.9818 5.02025 20.9818 3.02025C18.9818 1.02025 17.4018 1.66025 16.0418 3.02025Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.9102 4.15039C15.5802 6.54039 17.4502 8.41039 19.8502 9.09039" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      </g>
      </g>
    </svg>
  )
}

export default EditSvg;