import { default as cookie_lib } from "cookie";

interface ISetCookie {
  name: string;
  value: string | number | boolean;
  options?: any;
}
class Cookie {
  getCookie<T>(name: string): (T | undefined) {
    if(typeof document === 'undefined') return undefined;
    let matches = document.cookie.match(
      new RegExp(
        "(?:^|; )" +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
          "=([^;]*)"
      )
    );
    return matches ? decodeURIComponent(matches[1]) as T : undefined;
  }

  setCookie({ name, value, options = {} }: ISetCookie) {
    options = {
      path: "/",
      
      // add other defaults here if necessary
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  deleteCookie(name: string) {
    this.setCookie({
      name,
      value: "",
      options: {
        "max-age": -1,
      },
    });
  }

  parseJwt (token: string) {
    if(!token) return null;


    if(typeof document === 'undefined') {
      return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    };


    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}

const cookie = new Cookie();

export default cookie;
