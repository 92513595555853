class BrowserStorage {
    public keys: Array<string> = [];
    public sessionStorageKeys: Array<string> = [];
    public get<T>(key: string, type: string = "primitive"): T | string | null {
      if (!(key in localStorage)) return "";
  
      let value = localStorage.getItem(key);
  
      if (type === "primitive") {
        return value;
      } else {
        return JSON.parse(value as string);
      }
    }
  
    public set<T>(key: string, value: T, type: string = "primitive"): void {
      if (type === "primitive" && typeof value === "string") {
        localStorage.setItem(key, value);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
  
      this.keys = [...this.keys, key];
    }
  
    public setSession<T>(key: string, value: T, type: string = "primitive") {
      if (type === "primitive" && typeof value === "string") {
        sessionStorage.setItem(key, value);
      } else {
        sessionStorage.setItem(key, JSON.stringify(value));
      }
  
      this.sessionStorageKeys = [...this.sessionStorageKeys, key];
    }
  
    public getSession<T>(
      key: string,
      type: string = "primitive"
    ): T | string | null {
      if (!(key in localStorage)) return "";
  
      let value = sessionStorage.getItem(key);
  
      if (type === "primitive") {
        return value;
      } else {
        return JSON.parse(value as string);
      }
    }
  
    public clearSession() {
      sessionStorage.clear();
      this.sessionStorageKeys = [];
    }
  
    public removeFromSession(key: string) {
      sessionStorage.removeItem(key);
  
      this.sessionStorageKeys =
        this.sessionStorageKeys.filter((_key) => _key !== key) || [];
    }
  
    public clear(): void {
      localStorage.clear();
      this.keys = [];
    }
  
    public remove(key: string): void {
      localStorage.removeItem(key);
  
      this.keys = this.keys.filter((_key) => _key !== key) || [];
    }
  }
  
  const browserStorage = new BrowserStorage();
  
  export default browserStorage;
  