import React from 'react';
import { ThemeProvider as Provider } from '@mui/material/styles';
import theme from './MUI/mui-theme';
import CssBaseline from '@mui/material/CssBaseline';

type Props = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: Props) => {
  return (
    <Provider theme={theme}>
      <CssBaseline />
      {children}
    </Provider>
  );
};

export default ThemeProvider;
