import React, { FC } from "react";
import { ICommonSvgProps } from ".";

const AdClock: FC<Omit<ICommonSvgProps, "name">> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6673 7.99967C14.6673 11.6797 11.6807 14.6663 8.00065 14.6663C4.32065 14.6663 1.33398 11.6797 1.33398 7.99967C1.33398 4.31967 4.32065 1.33301 8.00065 1.33301C11.6807 1.33301 14.6673 4.31967 14.6673 7.99967Z"
        stroke="#818181"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4739 10.1202L8.40724 8.88684C8.04724 8.6735 7.75391 8.16017 7.75391 7.74017V5.00684"
        stroke="#818181"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AdClock;
