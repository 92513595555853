import useSWR from 'swr';
import { QUERY_KEYS } from '../keys';
import RegionApi from '@/Services/Axios/Controllers/RegionApi';
import { toUrlSafeString } from '@/Utils/toUrlSafeString';

type TodoDistrict = {
  slug: string;
  slugRu: string;
  regionId: number;
  regionName: {
    nameUz: string;
    nameRu: string;
    nameEn: string;
  };
  id: number;
  nameEn: string;
  nameRu: string;
  nameUz: string;
};

type TODO_CHANGE = {
  slug: string;
  slugRu: string;
  districts: TodoDistrict[];
  id: number;
  nameEn: string;
  nameRu: string;
  nameUz: string;
};

export function useGetRegions() {
  return useSWR(
    QUERY_KEYS.GET_REGIONS,
    async () => {
      const response = await RegionApi.getRegions();

      const listWithSlug: TODO_CHANGE[] = [
        ...response.data.map(region => {
          return {
            ...region,
            slug: region.slugUz,
            // slug: toUrlSafeString(region.nameUz.toLowerCase() + ' ' + region.id + 'r'),
            // slugRu: toUrlSafeString(region.nameRu.toLowerCase() + ' ' + region.id + 'r'),

            districts: region.districts.map(district => {
              return {
                ...district,
                slug: district.slugUz,
                // slug: toUrlSafeString(district.nameUz.toLowerCase() + ' ' + district.id + 'd'),
                // slugRu: toUrlSafeString(district.nameRu.toLowerCase() + ' ' + district.id + 'd'),

                regionId: region.id,
                regionName: {
                  nameUz: region.nameUz,
                  nameRu: region.nameRu,
                  nameEn: region.nameEn,
                },
              };
            }),
          };
        }),
      ];

      return {
        data: listWithSlug,
        indexedData: Object.fromEntries(
          listWithSlug.reduce((acc, item) => {
            acc.push([item.slug, item]);
            acc.push([item.slugRu, item]);

            item.districts.map(district => {
              acc.push([district.slug, district]);
              acc.push([district.slugRu, district]);
            });

            return acc;
          }, [] as [string, TODO_CHANGE | TodoDistrict][])
        ),
      };
    },
    {
      revalidateOnMount: true,
    }
  );
}
