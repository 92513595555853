import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const UserSvg: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.9446 19.042C16.7843 17.9507 15.9165 15 11 15C6.19435 15 5.23774 17.8868 5.04733 19.0361M5.04733 19.0361C2.59153 17.2139 1 14.2928 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21C8.76999 21 6.71046 20.2701 5.04733 19.0361ZM14 9C14 10.6569 12.6569 12 11 12C9.34315 12 8 10.6569 8 9C8 7.34315 9.34315 6 11 6C12.6569 6 14 7.34315 14 9Z'
        stroke='#0C88C6'
        strokeWidth='2'
      />
    </svg>
  );
};

export default UserSvg;
