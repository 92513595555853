import { IDistrict, IMyRegion, IRegion } from '@/Models/Region/IRegion';
import apiService from '../ApiService';

async function getRegions() {
  return await apiService.setUrl(`/region/with-districts`).request<IRegion[]>();
}

async function getRegionByLangCode(params: { lat: string | number; lon: string | number; lang: string }) {
  return await apiService.setUrl(`/region/langCode`).setParams(params).request<IMyRegion>();
}

async function getDistrictByRegion(id: string | number) {
  return await apiService.setUrl(`region/${id}/districts`).request<IDistrict[]>();
}

const RegionApi = {
  getRegions,
  getDistrictByRegion,
  getRegionByLangCode,
};

export { getRegions, getDistrictByRegion, getRegionByLangCode };
export default RegionApi;
