import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const AngleLeft: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.59971 4.87982L6.18971 0.289816C6.37707 0.103565 6.63052 -0.000976562 6.89471 -0.000976562C7.1589 -0.000976562 7.41235 0.103565 7.59971 0.289816C7.69344 0.382779 7.76783 0.49338 7.8186 0.615239C7.86937 0.737098 7.89551 0.867804 7.89551 0.999816C7.89551 1.13183 7.86937 1.26253 7.8186 1.38439C7.76783 1.50625 7.69344 1.61685 7.59971 1.70982L2.99971 6.28982C2.90598 6.38278 2.83159 6.49338 2.78082 6.61524C2.73005 6.7371 2.70391 6.86781 2.70391 6.99982C2.70391 7.13183 2.73005 7.26253 2.78082 7.38439C2.83159 7.50625 2.90598 7.61685 2.99971 7.70982L7.59971 12.2898C7.78801 12.4768 7.89433 12.7309 7.89527 12.9963C7.8962 13.2616 7.79169 13.5165 7.60471 13.7048C7.41773 13.8931 7.16361 13.9994 6.89824 14.0004C6.63288 14.0013 6.37801 13.8968 6.18971 13.7098L1.59971 9.11982C1.03791 8.55732 0.722348 7.79482 0.722348 6.99982C0.722348 6.20481 1.03791 5.44232 1.59971 4.87982Z'
        fill='#fff'
      />
    </svg>
  );
};

export default AngleLeft;
