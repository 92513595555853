import React, { FC } from 'react';
import { useActions } from './useActions';
import ModalContext from './Context';

interface IAuthProviderProps {
  children: React.ReactNode;
  initialValue: boolean;
};

const AuthProvider: FC<IAuthProviderProps> = ({ children, initialValue }) => {
  const state = useActions(initialValue);
  
  return (
    <ModalContext.Provider value={state}>
      { children }
    </ModalContext.Provider>
  )
}

export default AuthProvider;
