import { useCallback, useState } from "react";

export function useActions () {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openCtaMobileModal = useCallback(() => {
    setIsOpen(true);
    const paddingRight = getScrollbarWidth();

    // document.body.style.overflow = 'hidden';
    // document.body.style.paddingRight = `${paddingRight}px`;
  }, []);

  const closeCtaMobileModal = useCallback(() => {
    setIsOpen(false);
    
    // document.body.style.overflow = 'auto';
    // document.body.style.paddingRight = '0px';
  }, []);


  return {
    isOpen,
    openCtaMobileModal,
    closeCtaMobileModal,
  }
}

export type ModalContextType = ReturnType<typeof useActions>;

function getScrollbarWidth() {

  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  // @ts-ignore
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode!.removeChild(outer);

  return scrollbarWidth;
}
