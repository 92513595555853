export const QUERY_KEYS = {
  GET_ME: 'GET_ME',

  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_REGIONS: 'GET_REGIONS',

  // ----------------------------  HOMEPAGE BANNERS  ----------------------------
  GET_HOMEPAGE_BANNERS: 'GET_HOMEPAGE_BANNERS',

  // ----------------------------  PRODUCTS  ----------------------------
  GET_FILTERED_PRODUCTS: 'GET_FILTERED_PRODUCTS',
  GET_PRODUCTS_FOR_HOMEPAGE: 'GET_PRODUCTS_FOR_HOMEPAGE',
} as const;
