import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const OfferSvg: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.55565 1C2.35131 1 1.375 1.97631 1.375 3.18065V9.36986C1.375 10.5266 1.83449 11.6359 2.6524 12.4538L9.92123 19.7226C11.6244 21.4258 14.3858 21.4258 16.089 19.7226L20.0976 15.714C21.8008 14.0109 21.8008 11.2494 20.0976 9.54623L12.8288 2.2774C12.0109 1.45949 10.9016 1 9.74486 1H3.55565ZM8.64384 8.26884C9.44672 7.46595 9.44676 6.16423 8.64384 5.3613C7.84091 4.55838 6.53918 4.55842 5.7363 5.3613C4.93342 6.16418 4.93338 7.46591 5.7363 8.26884C6.53923 9.07176 7.84095 9.07172 8.64384 8.26884Z'
        stroke='#9DA0A9'
        strokeWidth='2'
      />
    </svg>
  );
};

export default OfferSvg;
