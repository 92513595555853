import React from "react";
import { useTranslation } from "react-i18next";

const ErrorBoundaryWrapper = () => {
  const { i18n } = useTranslation("common");
  const language = i18n.language;

  return (
    <>
      <img src="/static/images/error.png" alt="404" />
      <h1>
        {
          language === 'uz' ? "Nimadir xato ketdi." : "Что-то пошло не так."
        }
      </h1>
      <p className="description">
        {
          language === 'uz' ? "Biz buning ustida ishlayapmiz va imkon qadar tezroq tuzatamiz" : "Мы работаем над этим и исправим как можно скорее"
        }
      </p>
      {
        // eslint-disable-next-line @next/next/no-html-link-for-pages
        <a href="/">Bosh sahifaga o'tish</a>
      }
    </>
  );
};

export default ErrorBoundaryWrapper;
