import React, { FC } from 'react'
import { StyledLoader } from './style';
import { colors } from '@/styles/colors';

interface ILoaderProps {
  color: string;
  type: 'blockCentered' | 'block' | 'inline' | 'fullscreen';
  duration?: number;
  size?: number;
  className?: string;
}

const Loader: FC<ILoaderProps> = ({ color, type, duration = 1.7, size = 40, className = '' }) => {
  const spinner = <StyledLoader data-color={color} duration={duration} data-size={size} />;

  if(type === 'fullscreen') {
    return (
      <div style={{
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        left: 0,

        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.snow,
      }}
      className={className}
      >
        {spinner}
      </div>
    )
  }
  if(type === 'block') {
    return (
      <div style={{
        width: '100%',
        paddingTop: '3rem',
        minHeight: '20rem',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center'
      }}
      className={className}
      >
        {spinner}
      </div>
    )
  }
  if(type === 'blockCentered') {
    return (
      <div style={{
        width: '100%',
        paddingTop: '3rem',
        minHeight: '20rem',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      className={className}
      >
        {spinner}
      </div>
    )
  }

  return spinner;
}

export default Loader;