import React, { FC } from 'react';
import { useActions } from './useActions';
import ModalContext from './Context';

interface IModalProviderProps {
  children: React.ReactNode;
};

const ModalProvider: FC<IModalProviderProps> = ({ children }) => {
  const state = useActions();
  
  return (
    <ModalContext.Provider value={state}>
      { children }
    </ModalContext.Provider>
  )
}

export default ModalProvider;
