import { AUTH_VALUES } from "@/Services/Auth";
import apiService from "../ApiService";
import { IUser } from "@/Models/User/IUser";
import axios from "axios";
import { BASE_URL } from "../Config";
import { logger } from "@/Services/Logger";

async function getMe() {
  return await apiService
    .setUrl(`/users/me`)
    .setMethod("GET")
    .request<IUser>();
};

async function logout() {
  return await apiService
    .setUrl(`/users/logout`)
    .setMethod("POST")
    .request<boolean>();
};

async function deleteMe() {
  return await apiService
    .setUrl(`/users`)
    .setMethod("DELETE")
    .request();
};

async function sendCode(body: { phoneNumber: string }) {
  return await apiService
    .setUrl(`/auth/login`)
    .setBody({ username: body.phoneNumber })
    .setMethod("POST")
    .request();
};

async function verifyCode(body: AUTH_VALUES) {
  return await apiService
    .setUrl(`/auth/verify`)
    .setBody(body)
    .setMethod("POST")
    .request();
};

async function refreshToken(body: { token: string; phone: string; }) {
  return await apiService
    .setUrl(`/auth/refresh`)
    .setParams(body)
    .setMethod("GET")
    .request<{ id_token: string }>();
};

async function refreshTokenWithAxios(body: { token: string; phone: string; }): Promise<string | null> {
  try {
    const res = await axios(`${BASE_URL}/mobile/api/auth/refresh`, { params: body });
    return res.data.id_token;
  } catch (err) {
    logger.log(err)
    return null;
  }
};

async function resendCode(body: { token: string; phone: string; }) {
  return await apiService
    .setUrl(`/auth/resend`)
    .setParams(body)
    .setMethod("GET")
    .request();
};

const AuthApi = {
  sendCode,
  verifyCode,
  refreshToken,
  resendCode,
  getMe,
  refreshTokenWithAxios,
  deleteMe,
  logout
}

export default AuthApi;
