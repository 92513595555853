import React, { FC } from 'react';
import Logo from './heart';
import Heart from './heart';
import AngleLeft from './angleLeft';
import AngleRight from './angleRight';
import IconBxCategory from './categoryBx';
import PersonWalk from './personWalk';
import OfferSvg from './offerSvg';
import GridSvg from './grid';
import ListSvg from './list';
import UserSvg from './user';
import WalletSvg from './wallet';
import ChatsSvg from './chats';
import LanguageSvg from './language';
import MyPostsSvg from './myPosts';
import NotificationSvg from './notification';
import UserSettingsSvg from './user_settings';
import Offer2Svg from './offer2Svg';
import MessageSvg from './message';
import EyeSvg from './eye';

import ArrowLeftSvg from './arrowLeft';
import CameraSvg from './camera';
import EditSvg from './edit';
import PhoneSvg from './phone';
import MarkerSvg from './marker';
import AdClock from './adClock';
import AdEye from './adEye';
import AdPhone from './adPhone';
import AdOffer from './adOffer';
import AdFavourite from './adFavourite';
import CloseSvg from './close';
import SearchSvg from './Search';
import DocumentSvg from './document';
import FilterSvg from './filterSvg';
import LocationSvg from './location';
import LocationCreate from './locationCreate';
import NewHeartSvg from './NewHeart';
import HandshakeSvg from './HandshakeSvg';
import ShareSvg from '@/Components/atoms/CommonSvg/shareSvg';

export enum svgNames {
  Logo = 'Logo',
  Heart = 'Heart',
  AngleLeft = 'AngleLeft',
  AngleRight = 'AngleRight',
  Category = 'Category',
  PersonWalk = 'PersonWalk',
  Offer = 'Offer',
  Offer2 = 'Offer2',
  Message = 'Message',
  Grid = 'Grid',
  List = 'List',
  User = 'User',
  Share = 'Share',
  Eye = 'Eye',
  AdClock = 'AdClock',
  AdEye = 'AdEye',
  AdPhone = 'AdPhone',
  AdOffer = 'AdOffer',
  AdFavourite = 'AdFavourite',
  LocationCreate = 'LocationCreate',
  Wallet = 'Wallet',
  My_posts = 'My_posts',
  Chats = 'Chats',
  User_settings = 'User_settings',
  Notifications = 'Notifications',
  Language = 'Language',
  Document = 'Document',

  ArrowLeft = 'ArrowLeft',
  Camera = 'Camera',
  Edit = 'Edit',
  Phone = 'Phone',
  Marker = 'Marker',
  Close = 'Close',
  Search = 'Search',
  Filter = 'Filter',
  Location = 'Location',
  NewHeart = 'NewHeart',
  Handshake = 'Handshake',
}

export interface ICommonSvgProps {
  name: svgNames;
  [key: string]: any;
}

const CommonSvg: FC<ICommonSvgProps> = ({ name, ...props }) => {
  switch (name) {
    case svgNames.Logo: {
      return <Logo {...props} />;
    }
    case svgNames.LocationCreate: {
      return <LocationCreate {...props} />;
    }
    case svgNames.Heart: {
      return <Heart {...props} />;
    }
    case svgNames.NewHeart: {
      return <NewHeartSvg {...props} />;
    }
    case svgNames.Handshake: {
      return <HandshakeSvg {...props} />;
    }
    case svgNames.AngleLeft: {
      return <AngleLeft {...props} />;
    }
    case svgNames.AngleRight: {
      return <AngleRight {...props} />;
    }
    case svgNames.Category: {
      return <IconBxCategory {...props} />;
    }
    case svgNames.PersonWalk: {
      return <PersonWalk {...props} />;
    }
    case svgNames.Offer: {
      return <OfferSvg {...props} />;
    }
    case svgNames.Offer2: {
      return <Offer2Svg {...props} />;
    }
    case svgNames.Message: {
      return <MessageSvg {...props} />;
    }
    case svgNames.Grid: {
      return <GridSvg {...props} />;
    }
    case svgNames.List: {
      return <ListSvg {...props} />;
    }
    case svgNames.User: {
      return <UserSvg {...props} />;
    }
    case svgNames.Share: {
      return <ShareSvg {...props} />;
    }
    case svgNames.Wallet: {
      return <WalletSvg {...props} />;
    }
    case svgNames.Chats: {
      return <ChatsSvg {...props} />;
    }
    case svgNames.Language: {
      return <LanguageSvg {...props} />;
    }
    case svgNames.My_posts: {
      return <MyPostsSvg {...props} />;
    }
    case svgNames.Notifications: {
      return <NotificationSvg {...props} />;
    }
    case svgNames.Eye: {
      return <EyeSvg {...props} />;
    }
    case svgNames.User_settings: {
      return <UserSettingsSvg {...props} />;
    }
    case svgNames.ArrowLeft: {
      return <ArrowLeftSvg {...props} />;
    }
    case svgNames.Camera: {
      return <CameraSvg {...props} />;
    }
    case svgNames.Edit: {
      return <EditSvg {...props} />;
    }
    case svgNames.Phone: {
      return <PhoneSvg {...props} />;
    }
    case svgNames.Marker: {
      return <MarkerSvg {...props} />;
    }
    case svgNames.AdClock: {
      return <AdClock {...props} />;
    }
    case svgNames.AdEye: {
      return <AdEye {...props} />;
    }
    case svgNames.AdPhone: {
      return <AdPhone {...props} />;
    }
    case svgNames.AdOffer: {
      return <AdOffer {...props} />;
    }
    case svgNames.AdFavourite: {
      return <AdFavourite {...props} />;
    }
    case svgNames.Close: {
      return <CloseSvg {...props} />;
    }
    case svgNames.Document: {
      return <DocumentSvg {...props} />;
    }
    case svgNames.Search: {
      return <SearchSvg {...props} />;
    }
    case svgNames.Filter: {
      return <FilterSvg {...props} />;
    }
    case svgNames.Location: {
      return <LocationSvg {...props} />;
    }
    default: {
      return null;
    }
  }
};

export default CommonSvg;
