import { logger } from "@/Services/Logger";

export const _DEFAULT_GEO = {
  user_lat: 41.340774,
  user_lang: 69.287355
};

export async function asyncGetClientCoordinates(): Promise<{user_lat: number, user_lang: number}> {
  const GEO_LOCATION = { ..._DEFAULT_GEO };

  return new Promise((resolve, reject) => {

    function SUCCESS_GET_LOCATION(position: any) {
      GEO_LOCATION.user_lat = position.coords.latitude;
      GEO_LOCATION.user_lang = position.coords.longitude;
      localStorage.setItem('user_coords', `${GEO_LOCATION.user_lat.toString()}-${GEO_LOCATION.user_lang.toString()}`);

      resolve(GEO_LOCATION);
    }
  
    if(typeof window !== 'undefined') {
      window?.navigator?.geolocation?.getCurrentPosition(SUCCESS_GET_LOCATION, () => resolve(GEO_LOCATION), { enableHighAccuracy: true,
        timeout: 9000,
        maximumAge: 0
      });
    };
  });
}

export function getClientCoordinates(): {user_lat: number, user_lang: number} {
  const GEO_LOCATION = { ..._DEFAULT_GEO };

  function SUCCESS_GET_LOCATION(position: any) {
    GEO_LOCATION.user_lat = position.coords.latitude;
    GEO_LOCATION.user_lang = position.coords.longitude;
    localStorage.setItem('user_coords', `${GEO_LOCATION.user_lat.toString()}-${GEO_LOCATION.user_lang.toString()}`);
  }

  if(typeof window !== 'undefined') {
    window?.navigator?.geolocation?.getCurrentPosition(SUCCESS_GET_LOCATION, () => {}, { enableHighAccuracy: true,
      timeout: 9000,
      maximumAge: 0
    });
  };

  try {
    const [lat, lang] = localStorage.getItem('user_coords')?.split('-') || [];
    if(lat && lang) {
      GEO_LOCATION.user_lat = parseFloat(lat);
      GEO_LOCATION.user_lang = parseFloat(lang);
      _DEFAULT_GEO.user_lat = parseFloat(lat)
      _DEFAULT_GEO.user_lang = parseFloat(lang);
    };
  } catch(e) {
    logger.warn(e);
  }

  return GEO_LOCATION;
}


export async function asyncCalculateDistance(lat: number, lan: number): Promise<number> {
  const { user_lang, user_lat } = await asyncGetClientCoordinates();
  var R = 6371; // km
  var dLat = toRad(lat-user_lat);
  var dLon = toRad(lan-user_lang);
  var lat1 = toRad(user_lat);
  var lat2 = toRad(lat);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}

export function calculateDistance(lat: number, lan: number): number {
  const { user_lang, user_lat } = getClientCoordinates();
  var R = 6371; // km
  var dLat = toRad(lat-user_lat);
  var dLon = toRad(lan-user_lang);
  var lat1 = toRad(user_lat);
  var lat2 = toRad(lat);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(Value: number) {
    return Value * Math.PI / 180;
}

// export function toKmM(distance: number) {
//   if(distance > 1000) {
//     return (distance / 1000).toFixed(2) + 'km';
//   } else {
//     return distance.toFixed(0) + 'm';
//   }
// }

export function toKmM(distance: number) {
  if (distance > 1000) {
    const kilometers = (distance / 1000).toFixed(2);
    return kilometers.replace(/\.?0+$/, '') + 'km';
  } else {
    const meters = distance.toFixed(0);
    return meters.replace(/\.?0+$/, '') + 'm';
  }
}