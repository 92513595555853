import { createContext } from "use-context-selector";
import { AuthContextType } from "./useActions";

const AuthContext = createContext<AuthContextType>({
  state: {
    isLogin: false,
    modalVisible: false,
    userData: null,
    requirePath: null
  },
  actions: {
    login() {},
    logout() {},
    showModal() {},
    closeModal() {},
    updateUserData() {},
    async getMe() {},
    async fetchUserData() {},
  }
});

export default AuthContext;
