// @ts-expect-error
const warn = (...args) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  console.warn(...args);
};

class GTM {
  CONTAINER_ID: string = '';

  initialized = false;

  configure(config: { containerId: string }) {
    if (!config.containerId) {
      warn('GTM requires a GTM ID to be loaded.');
      return;
    }

    this.CONTAINER_ID = config.containerId;
  }

  initialize(config: { containerId: string }) {
    if (this.initialized) {
      warn('GTM can only be initialized once.');
      return;
    }

    // Maybe you want to load events from server side (in NextJS apps for example),
    // those can be queued.
    // SSR queued events can be loaded in the initialize script.
    // For the moment we do not implement it, but in future we might add it.

    if (!document) {
      warn('GTM can be initialized only on client side.');
      return;
    }

    this.configure(config);

    if (!this.CONTAINER_ID) {
      return;
    }

    // const meta = document.createElement('meta');
    const script = document.createElement('script');
    const noscript = document.createElement('noscript');
    
    // meta.setAttribute('name', 'google-site-verification')
    // meta.setAttribute('content', '0IIfM52o_E3KKLiSjb8epwFYy4fO3Y4jfXgiFVFrq7E')
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${this.CONTAINER_ID}');
    `;
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${this.CONTAINER_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;

    // document.head.insertBefore(meta, document.head.childNodes[0]);
    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noscript, document.body.childNodes[0]);
  }

  // @ts-expect-error
  push(...args) {
    if (!window) {
      warn('GTM push works only on client side.');
      return;
    }

    // @ts-expect-error
    if (!window.dataLayer) {
      // @ts-expect-error
      window.dataLayer = [];
    }

    // @ts-expect-error
    window.dataLayer.push(...args);
  }
}

// Singleton
export const gtm = new GTM();
