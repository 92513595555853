import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const CloseSvg: FC<Omit<ICommonSvgProps, 'name'>> = (props) => {
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g id="Group 104">
      <g id="Group 103">
      <path id="Vector 1" d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
      <path id="Vector 2" d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
      </g>
      </g>
    </svg>
  )
}

export default CloseSvg;