import { colors } from '@/styles/colors';
import { styled, css } from '@mui/material/styles';
import Link from 'next/link';

export interface IBaseButtonAttr extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  [key: string]: any;
};

const CreateButtonStyles = (html_tag: 'button' | typeof Link) => {

  // @ts-ignore
  return styled(html_tag)<IBaseButtonAttr>`
    padding: 10px 10px;
    border-radius: 10px;
    font-size: ${props => props.theme.customTheme.sizes.normal};
    line-height: 19px;
    font-weight: 400;
    border: 0;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;

    display: flex;
    align-items: center;
    outline: 0;

    span{
      margin: auto;
    }

    // ------------ PRIMARY --------------------
    ${props => props['data-btntype'] === 'primary' ? css`
      background-color: ${colors.primary};
      color: #fff;

      &:focus {
        box-shadow: 0 0 0 1px ${colors.primary};
      }
    ` : ''}
    
    // ------------ PRIMARY-OUTLINED --------------------
    ${props => props['data-btntype'] === 'primary-outlined' ? css`
      background-color: transparent;
      border: 1px solid ${colors.primary};
      color: ${colors.primary};

      &:focus {
        box-shadow: 0 0 0 1px ${colors.primary};
      }
    ` : ''}
    
    // ------------ ORANGE --------------------
    ${props => props['data-btntype'] === 'orange' ? css`
      background-color: ${colors.orange};
      color: #fff;

      &:focus {
        box-shadow: 0 0 0 1px ${colors.primary};
      }
    ` : ''}
    
    // ------------ ORANGE-OUTLINED --------------------
    ${props => props['data-btntype'] === 'orange-outlined' ? css`
      background-color: transparent;
      border: 1px solid ${colors.orange};
      color: ${colors.orange};

      &:focus {
        box-shadow: 0 0 0 1px ${colors.primary};
      }
    ` : ''}
    
    // ------------ LIGHT-PRIMARY --------------------
    ${props => props['data-btntype'] === 'light-primary' ? css`
      background-color: ${colors.lightPrimary};
      color: ${colors.dark};

      &:focus {
        box-shadow: 0 0 0 1px ${colors.primary};
      }
    ` : ''}
    
    // ------------ LIGHT-PRIMARY-OUTLINED --------------------
    ${props => props['data-btntype'] === 'light-primary-outlined' ? css`
      background-color: transparent;
      border: 1px solid ${colors.lightPrimary};
      color: ${colors.dark};

      &:focus {
        box-shadow: 0 0 0 1px ${colors.lightPrimary};
      }
    ` : ''}


    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }


    ${props => props['data-loading'] ? css`
      gap: 12px;
      justify-content: center;
    ` : ''}

    @media (max-width: 767.98px) {
      span {
        font-size: ${props => props.theme.customTheme.sizes.middle};
      }
    }
  `;
}

const StyledBaseButton = CreateButtonStyles('button');
const StyledBaseButtonLink = CreateButtonStyles(Link);

export {
  StyledBaseButton,
  StyledBaseButtonLink,
};
