import { MessagePayload } from "firebase/messaging";

export type payloadType = { data: {
  type: FirebaseMessageTypeEnum;
  [key: string]: string;
} } & Omit<MessagePayload, 'data'>

export type typeMessageHandlerFunction = (payload: payloadType) => void;

export enum FirebaseMessageTypeEnum {
  USER_MESSAGE_NOTIFICATION = 'UserMessageNotificationType',
  MODERATOR_REJECTED_PRODUCT = 'ModeratorRejectedProduct',
  MODERATOR_ACCEPTED_PRODUCT = 'ModeratorAcceptedProduct',
  CHAT_MESSAGE = 'ChatMessage',
  BUYER_CHANGED_OFFER = 'BuyerChangedOffer',
  SELLER_CHANGED_OFFER = 'SellerChangedOffer',
}
