import React, { ErrorInfo } from 'react';
import BoundaryErrorWrapper from './style';
import ErrorBoundaryWrapper from './ErrorBoundaryWrapper';
import * as Sentry from "@sentry/nextjs";
import { logger } from '@/Services/Logger';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.log({ error, errorInfo });
    Sentry.captureException(error)
  }

  
  render() {

    if (this.state.hasError) {
      return (
        <BoundaryErrorWrapper>
          <ErrorBoundaryWrapper/>
        </BoundaryErrorWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
