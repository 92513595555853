import { createContext } from "use-context-selector";
import { ModalContextType } from "./useActions";

const ModalContext = createContext<ModalContextType>({
  isOpen: false,
  openCtaMobileModal: () => {},
  closeCtaMobileModal: () => {},
});

export default ModalContext;
