import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const Heart: FC<Omit<ICommonSvgProps, 'name'>> = (props) => {
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg"width="25" height="24" viewBox="0 0 25 24" fill="currentColor" {...props}>
      <path d="M13.12 20.8096C12.78 20.9296 12.22 20.9296 11.88 20.8096C8.98 19.8196 2.5 15.6896 2.5 8.68961C2.5 5.59961 4.99 3.09961 8.06 3.09961C9.88 3.09961 11.49 3.97961 12.5 5.33961C13.51 3.97961 15.13 3.09961 16.94 3.09961C20.01 3.09961 22.5 5.59961 22.5 8.68961C22.5 15.6896 16.02 19.8196 13.12 20.8096Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='currentColor'/>
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="currentColor" >
    // <path d="M13.12 20.8096C12.78 20.9296 12.22 20.9296 11.88 20.8096C8.98 19.8196 2.5 15.6896 2.5 8.68961C2.5 5.59961 4.99 3.09961 8.06 3.09961C9.88 3.09961 11.49 3.97961 12.5 5.33961C13.51 3.97961 15.13 3.09961 16.94 3.09961C20.01 3.09961 22.5 5.59961 22.5 8.68961C22.5 15.6896 16.02 19.8196 13.12 20.8096Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='currentColor'/>
    // </svg>
  )
}

export default Heart;