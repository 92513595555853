import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { AuthProvider } from '@/Context/Auth';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation, useTranslation } from 'next-i18next';
import Loader from '@/Components/atoms/Loader';
import ScrollToTop from '@/Components/atoms/ScrollToTop';
import useRouterChange from '@/Hooks/useRouterChange';
import ModalProvider from '@/Context/Modal/Provider';
import { IsSsrMobileContextProvider } from '@/Context/IsSsrMobile';
import ErrorBoundary from '@/Components/atoms/ErrorBoundary/ErrorBoundary';
import { GlobalSiteSettingsProvider } from '@/Context/GlobalSiteSettings';
import { firebaseConfig, firebase_instance } from '@/Services/Firebase';
import SwrProvider from '@/ServerState/Provider';

// ----------------------- LAZY LOADING COMPONENTS ----------------
const LoginModal = dynamic(() => import('@/Components/moleculas/LoginModal'), { ssr: false });
const CtaMobileModal = dynamic(() => import('@/Components/atoms/CtaMobileModal'), { ssr: false });
const ConfirmModalController = dynamic(() => import('@/Context/GlobalSiteSettings/Components/ConfirmModalController'), { ssr: false });

// ----------------------- MUI EMOTION ----------------
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '../styles/MUI/createEmotionCache';

// ----------------------- LIBRARY CSS STYLES ---------------------------------
import { colors } from '@/styles/colors';
import ThemeProvider from '@/styles/ThemeProvider';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/controller';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'antd-notifications-messages/lib/styles/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'simplebar-react/dist/simplebar.min.css';
import '../styles/MUI/globals.scss';
import { gtm } from '@/Services/Gtm';
// ----------------------- END --- LIBRARY CSS STYLES ---------------------------------
export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function App({ Component, ...props }: MyAppProps) {
  const { emotionCache = clientSideEmotionCache, pageProps } = props;
  const loading = useRouterChange();
  const { i18n } = useTranslation();

  useEffect(() => {
    // CSS 1vh HEIGHT
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();

    return () => {
      window.removeEventListener('resize', appHeight);
      firebase_instance.destroy();
    };
  }, []);

  useEffect(() => {
    gtm.initialize({ containerId: 'GTM-KKRM76TX' });
    if(typeof localStorage !== 'undefined'){
      localStorage?.setItem('BROWSER_LANGUAGE', i18n.language);
    }
  }, []);

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' />
        <style jsx global>{`
          html {
            overflov: ${loading ? 'hidden' : 'visible'};
            height: ${loading ? '100vh' : 'auto'};
          }
        `}</style>
        <link rel='icon' href='/static/favicon.ico' />
        <link rel='apple-touch-icon' href='/static/favicon.ico' />
      </Head>
      <>
        <SwrProvider swrFallbackData={pageProps.swrFallbackData}>
          <GlobalSiteSettingsProvider savedUserPreferences={pageProps.savedUserPreferences}>
            <ErrorBoundary>
              <IsSsrMobileContextProvider value={pageProps.isSsrMobileValues}>
                <CacheProvider value={emotionCache}>
                  <ThemeProvider>
                    <AuthProvider initialValue={pageProps.isLogin}>
                      <ModalProvider>
                        <Component {...pageProps} />
                        <ScrollToTop />

                        {/* DYNAMIC IMPORTS */}
                        <CtaMobileModal />
                        <LoginModal />
                        <ConfirmModalController />
                        {/* DYNAMIC IMPORTS END */}
                      </ModalProvider>
                    </AuthProvider>
                    {loading && <Loader color={colors.primary} duration={1.7} size={100} type='fullscreen' />}
                  </ThemeProvider>
                </CacheProvider>
              </IsSsrMobileContextProvider>
            </ErrorBoundary>
          </GlobalSiteSettingsProvider>
        </SwrProvider>
      </>
    </>
  );
}

export default appWithTranslation(App);
