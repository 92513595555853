import React from 'react';

type Props = {};

const SearchSvg = (props: Props) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.2957 19.7099C18.6878 20.0989 19.321 20.0964 19.7099 19.7043C20.0989 19.3123 20.0964 18.6791 19.7044 18.2901L18.2957 19.7099ZM14.8236 8.41179C14.8236 11.9529 11.9529 14.8236 8.41179 14.8236V16.8236C13.0575 16.8236 16.8236 13.0575 16.8236 8.41179H14.8236ZM8.41179 14.8236C4.87066 14.8236 2 11.9529 2 8.41179H0C0 13.0575 3.76609 16.8236 8.41179 16.8236V14.8236ZM2 8.41179C2 4.87066 4.87066 2 8.41179 2V0C3.76609 0 0 3.76609 0 8.41179H2ZM8.41179 2C11.9529 2 14.8236 4.87066 14.8236 8.41179H16.8236C16.8236 3.76609 13.0575 0 8.41179 0V2ZM12.917 14.3733L18.2957 19.7099L19.7044 18.2901L14.3256 12.9535L12.917 14.3733Z'
        fill='#9DA0A9'
      />
    </svg>
  );
};

export default SearchSvg;
