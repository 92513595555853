import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const HandshakeSvg: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='18' viewBox='0 0 24 18' fill='none' {...props}>
      <g clip-path='url(#clip0_206_1537)'>
        <path
          d='M17.9071 6.74997H12.5633V8.71872C12.5633 10.1144 11.4278 11.25 10.0321 11.25C8.63635 11.25 7.5008 10.1144 7.5008 8.71872V4.44372L5.21916 5.81482C4.54065 6.21911 4.1258 6.95388 4.1258 7.74138V9.40427L1.3133 11.0285C0.775411 11.3379 0.589083 12.0269 0.901974 12.5648L3.71447 17.4375C4.02385 17.9754 4.71291 18.1582 5.2508 17.8488L8.88596 15.75H13.6883C14.9293 15.75 15.9383 14.741 15.9383 13.5H16.5008C17.1231 13.5 17.6258 12.9972 17.6258 12.375V10.125H17.9071C18.3746 10.125 18.7508 9.7488 18.7508 9.28122V7.59372C18.7508 7.12614 18.3746 6.74997 17.9071 6.74997ZM23.0996 5.43513L20.2871 0.562472C19.9778 0.0245813 19.2887 -0.158231 18.7508 0.151144L15.1156 2.24997H11.5227C11.1008 2.24997 10.6895 2.3695 10.3309 2.59099L9.15315 3.32575C8.82268 3.52966 8.6258 3.89177 8.6258 4.27849V8.71872C8.6258 9.49568 9.2551 10.125 10.0321 10.125C10.809 10.125 11.4383 9.49568 11.4383 8.71872V5.62497H17.9071C18.9934 5.62497 19.8758 6.50739 19.8758 7.59372V8.59567L22.6883 6.97146C23.2262 6.65857 23.409 5.97302 23.0996 5.43513Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_206_1537'>
          <rect width='22.5' height='18' fill='currentColor' transform='translate(0.75)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HandshakeSvg;
