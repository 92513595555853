import React from 'react'
import GlobalSiteSettingsContext from '../Context';
import { useContextSelector } from 'use-context-selector';
import MuiModal from '@/Components/atoms/MuiModal';
import { IconButton, Stack, Typography } from '@mui/material';
import BaseButton from '@/Components/atoms/BaseComponents/BaseButton';
import CommonSvg, { svgNames } from '@/Components/atoms/CommonSvg';
import { useIsMobile } from '@/Hooks/useIsMobile';


const ConfirmModalController = () => {
  const isMobile = useIsMobile();
  const confirmPromises = useContextSelector(GlobalSiteSettingsContext, v => v.state.confirmPromises);
  const removeConfirmPromise = useContextSelector(GlobalSiteSettingsContext, v => v.actions.removeConfirmPromise);

  if(!confirmPromises.length) {
    return null;
  }

  
  const currentPromise = confirmPromises.slice(-1)[0];
  
  const rejectPromiseAndClose = () => {
    currentPromise.rejectCb();
    removeConfirmPromise(currentPromise.id);
  }
  
  const confirmPromiseAndClose = () => {
    currentPromise.resolveCb();
    removeConfirmPromise(currentPromise.id);
  }


  return (
    <MuiModal open fullScreen={isMobile} onClose={rejectPromiseAndClose} sx={{
      '.MuiDialog-paper': {
        padding: '1rem',
        borderRadius: isMobile ? 0 : '10px',
        boxShadow: 'none'
      }
    }}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2} marginBottom={2}>
        <Typography variant='h5'>
          { currentPromise.info.description }
        </Typography>
        <IconButton onClick={rejectPromiseAndClose} disableRipple>
          <CommonSvg name={svgNames.Close} />
        </IconButton>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2} onClick={rejectPromiseAndClose}>
        <BaseButton btnType='primary-outlined' style={{ width: '100%' }}>
          { currentPromise.info.cancellationText }
        </BaseButton>
        <BaseButton style={{ width: '100%' }} onClick={confirmPromiseAndClose}>
          { currentPromise.info.confirmationText }
        </BaseButton>
      </Stack>
    </MuiModal>
  )
}

export default ConfirmModalController;
