export type TODO_ANY = any;

export interface IKeyValue {
  [key: string]: any;
}

export enum LIST_GRID_TYPE {
  'MASONRY' = 'MASONRY',
  'GRID' = 'GRID',
  'HORIZONTAL' = 'HORIZONTAL',
}

export interface PageAble<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
}

export interface PageAbleParams {
  page: number;
  size: number;
}

export interface LocationType {
  user_lat: number;
  user_lang: number;
}
