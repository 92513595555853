import { SWRConfig } from 'swr';
import React from 'react';
import { QUERY_KEYS } from '@/ServerState/keys';
import { logger } from '@/Services/Logger';

type Props = {
  children: React.ReactNode;
  swrFallbackData?: Record<string, any>;
};

const SwrProvider = (props: Props) => {
  // logger.log('FALBACK_DATA_FRON: ', props.swrFallbackData);

  return (
    <SWRConfig
      value={{
        fallbackData: props.swrFallbackData ?? {},
        // fallback: props.swrFallbackData ?? {},
        errorRetryCount: 2,

        dedupingInterval: 60000, // 1 minute
        onError: error => logger.error(error),

        keepPreviousData: false,
        revalidateOnMount: true,
        revalidateOnFocus: false,
        // provider(cache) {
        //   logger.log('cache', cache);

        //   // TODO: continue
        //   // const JSON.parse(props.swrFallbackData ?? '{}');
        //   // cache.set(QUERY_KEYS.GET_FILTERED_PRODUCTS, {});

        //   // NOTE as soon as client makes request with some key it will be cached
        //   return cache;
        // },
      }}
    >
      {props.children}
    </SWRConfig>
  );
};

export default SwrProvider;
