import React, { FC } from 'react';
import { IBaseButtonAttr, StyledBaseButton, StyledBaseButtonLink } from './style';
import Loader from '../../Loader';

export interface IBaseButtonProps extends IBaseButtonAttr {
  children?: React.ReactNode;
  loading?: boolean;
  href?: string;
  btnType?: 'primary' | 'light-primary' | 'orange' | 'primary-outlined' | 'light-primary-outlined' | 'orange-outlined';
};


const BaseButton: FC<IBaseButtonProps> = ({ btnType = 'primary', loading, children, ...props }) => {

  let Component = StyledBaseButton;
  if(props.href) {
    Component = StyledBaseButtonLink;
  }

  return (
    <Component {...props} data-loading={loading} data-btntype={btnType}>
      <span>{children}</span>
      {loading && <Loader type='inline' size={14} color={'inherit'} />}
    </Component>
  )
}

export default BaseButton;
