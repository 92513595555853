export const bg = {
  lightPrimary: 'rgba(229,242,248,255)',
  primary: 'rgba(7,143,205,255)',
  darkerPrimary: 'rgba(77, 135, 155, 1)',
  darkPrimary: 'rgba(1,77,113,255)',
  darkestPrimary: '#002D42',
  snow: '#f9f9f9',
  categoryHead:'rgba(192, 192, 192, 0.20)',
  categoryLi:'#414141',
  orange: '#df7800',
  secondary: '#eee',
  danger: '#C31162',
  pink: '#C31162',
  pink20:'rgba(195, 17, 98, 0.20)',
  primary20:'rgba(12, 136, 198, 0.20);',

  success: 'green',
  error: 'red',
  warning: 'orange',
  
  dark: '#000',
  dark100: '#111',
  dark200: '#222',
  dark300: '#333',
  dark400: '#444',
  dark500: '#555',
  dark600: '#666',
  dark700: '#777',
  dark800: '#888',
  dark900: '#999',
}

export type typeBgColors = typeof bg;