/* eslint-disable no-console */
const isDebugMode = process.env.NODE_ENV === 'development';

export const logger = {
  log: (...args: any[]) => {
    if (isDebugMode) {
      console.log(...args);
    }
  },
  error: (...args: any[]) => {
    console.error(...args);
  },
  warn: (...args: any[]) => {
    if (isDebugMode) {
      console.warn(...args);
    }
  },
};
