import { useGetRegions } from '@/ServerState/hooks/useGetRegions';
import { cookie } from '@/Services/BrowserStorage';
import { DefaultUserPreferences, IUserPreferences } from '@/Services/SsrService/getUserPreferences';
import { PickPropType } from '@/Types/utils';
import { useCallback, useState } from 'react';
import { v4 } from 'uuid';

interface IConfirmInfo {
  description: React.ReactNode;
  confirmationText: React.ReactNode;
  cancellationText: React.ReactNode;
}

interface IConfirmPromiseItem {
  promise: Promise<void>;
  resolveCb: () => void;
  rejectCb: () => void;
  info: IConfirmInfo;
  id: string;
}

export function useActions(savedUserPreferences: IUserPreferences) {
  const [userPreferences, setUserPreferences] = useState(savedUserPreferences ?? DefaultUserPreferences);
  const [confirmPromises, setConfirmPromises] = useState<IConfirmPromiseItem[]>([]);
  const resionsQuery = useGetRegions();

  const regionOptions = resionsQuery.data?.data ?? [];

  const changeUserPreference = useCallback(<T extends keyof IUserPreferences>(key: T, value: PickPropType<IUserPreferences, T>) => {
    cookie.setCookie({
      name: key,
      value: JSON.stringify(value),
    });
    setUserPreferences(prev => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const confirm = useCallback((info: IConfirmInfo) => {
    const id = v4();
    let resolveCb = () => {};
    let rejectCb = () => {};
    const promise = new Promise<void>((resolve, reject) => {
      resolveCb = resolve;
      rejectCb = reject;
    });

    setConfirmPromises(p => [
      ...p,
      {
        promise,
        resolveCb,
        rejectCb,
        info,
        id,
      },
    ]);
    return promise;
  }, []);

  const removeConfirmPromise = useCallback((id: string) => {
    setConfirmPromises(p => p.filter(p => p.id !== id));
  }, []);

  return {
    state: {
      userPreferences,
      confirmPromises,
      regionOptions,
    },
    actions: {
      changeUserPreference,
      confirm,
      removeConfirmPromise,
    },
  };
}

export type GlobalSiteSettingsContextType = ReturnType<typeof useActions>;
