import { LIST_GRID_TYPE } from '@/Types/CommonTypes';
import { _DEFAULT_GEO } from '../Coordinates';
import encrypingService from '../EncrypingService';
import { AVAILABLE_PAGE_SIZE_TYPE } from '@/Data/Constants';
import { IUser } from '@/Models/User/IUser';

export type IUserPreferences = {
  gridType: LIST_GRID_TYPE;
  pageSize: AVAILABLE_PAGE_SIZE_TYPE;
  language: 'uz' | 'ru';
  location: {
    user_lang: number;
    user_lat: number;
  };
  userInfo: IUser | null;
  // REGION CITY
};

// NOTE: faqat bir joyda import qilish uchun export qilindi
export const DefaultUserPreferences: IUserPreferences = {
  gridType: LIST_GRID_TYPE.GRID,
  pageSize: 30,
  language: 'uz',
  location: {
    user_lang: _DEFAULT_GEO.user_lang,
    user_lat: _DEFAULT_GEO.user_lat,
  },
  userInfo: null,
};

async function tryToGetProp(
  cookies: Partial<{
    [key: string]: string;
  }>,
  key: keyof IUserPreferences
) {
  try {
    const value = cookies[key];
    if (value) {
      const data = await encrypingService.decryptData(value);
      return data;
    }
  } catch (error) {
    return undefined;
  }
}

export async function getUserPreferences(
  cookies: Partial<{
    [key: string]: string;
  }>
): Promise<IUserPreferences> {
  return {
    gridType: (await tryToGetProp(cookies, 'gridType')) ?? DefaultUserPreferences['gridType'],
    pageSize: (await tryToGetProp(cookies, 'pageSize')) ?? DefaultUserPreferences['pageSize'],
    language: (await tryToGetProp(cookies, 'language')) ?? DefaultUserPreferences['language'],
    location: (await tryToGetProp(cookies, 'location')) ?? DefaultUserPreferences['location'],
    userInfo: (await tryToGetProp(cookies, 'userInfo')) ?? DefaultUserPreferences['userInfo'],
  };
}
