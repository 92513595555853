import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const LocationCreate: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M12.0006 0C8.07635 0 4.86035 3.2064 4.86035 7.11984C4.86035 8.63616 5.34467 10.0454 6.16427 11.2037L11.1292 19.7868C11.8244 20.6952 12.2867 20.5226 12.8648 19.7388L18.3412 10.4196C18.4516 10.2194 18.5384 10.0063 18.614 9.78864C18.9615 8.9418 19.1402 8.03521 19.1401 7.11984C19.1404 3.2064 15.9253 0 12.0006 0ZM12.0006 3.336C14.114 3.336 15.7945 5.01264 15.7945 7.11984C15.7945 9.22704 14.114 10.903 12.0006 10.903C9.88739 10.903 8.20619 9.22728 8.20619 7.11984C8.20619 5.01264 9.88739 3.33624 12.0006 3.33624V3.336Z'
        fill='#151515'
      />
      <path
        d='M16.5395 11.738L16.528 11.7683C16.5316 11.7592 16.5345 11.7498 16.5381 11.7407L16.5395 11.738ZM8.16183 16.5738C4.77159 17.0528 2.40039 18.1988 2.40039 19.8858C2.40039 22.1581 6.36231 24.0001 12.0004 24.0001C17.6385 24.0001 21.6004 22.1581 21.6004 19.8858C21.6004 18.1988 19.2294 17.0528 15.8394 16.5738L15.3698 17.3735C17.9248 17.728 19.6804 18.4729 19.6804 19.3374C19.6804 20.5494 16.2419 21.5317 12.0004 21.5317C7.75887 21.5317 4.32039 20.5494 4.32039 19.3374C4.32015 18.4758 6.06423 17.7316 8.62719 17.3756C8.47239 17.1083 8.31687 16.8414 8.16183 16.5738Z'
        fill='#151515'
      />
    </svg>
  );
};

export default LocationCreate;
