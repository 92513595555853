import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const ListSvg: FC<Omit<ICommonSvgProps, 'name'>> = (props) => {
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" {...props}>
      <path d="M9.10449 16.5H17.4883" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.10449 10H17.4883" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.10449 3.5H17.4883" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.31992 2.88333C2.31992 2.47832 2.64825 2.15 3.05326 2.15H4.8977C5.30271 2.15 5.63103 2.47832 5.63103 2.88333V4.72778C5.63103 5.13279 5.30271 5.46111 4.8977 5.46111H3.05326C2.64825 5.46111 2.31992 5.13279 2.31992 4.72778V2.88333ZM2.31992 8.87778C2.31992 8.47277 2.64825 8.14444 3.05326 8.14444H4.8977C5.30271 8.14444 5.63103 8.47277 5.63103 8.87778V10.7222C5.63103 11.1272 5.30271 11.4556 4.8977 11.4556H3.05326C2.64825 11.4556 2.31992 11.1272 2.31992 10.7222V8.87778ZM2.31992 15.1149C2.31992 14.7099 2.64825 14.3816 3.05326 14.3816H4.8977C5.30271 14.3816 5.63103 14.7099 5.63103 15.1149V16.9594C5.63103 17.3644 5.30271 17.6927 4.8977 17.6927H3.05326C2.64825 17.6927 2.31992 17.3644 2.31992 16.9594V15.1149Z" stroke="currentColor" strokeWidth="1.3"/>
    </svg>
  )
}

export default ListSvg;