import { privateLinks } from '@/Data/privateLinks';
import { BROWSER_TOKEN_KEY, BROWSER_USER_PHONE } from '@/Services/Axios';
import { cookie } from '@/Services/BrowserStorage';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';
import AuthApi from '../Axios/Controllers/AuthApi';

export interface AUTH_VALUES {
  token: string;
  confirmationCode: string;
  applicationToken: string;
  platform: string;
}

class Auth {
  login(token: string, phone: string) {
    var date = new Date();
    var days = 30;
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    cookie.setCookie({
      name: BROWSER_TOKEN_KEY,
      value: token,
      options: {
        expires: date,
      },
    });
    cookie.setCookie({
      name: BROWSER_USER_PHONE,
      value: phone,
      options: {
        expires: date,
      },
    });
  }

  checkIsLoginServer(context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>): {
    isLogin: boolean;
    additional: object;
  } {
    const isLogin = context.req.cookies[BROWSER_TOKEN_KEY];
    const pathname = context.resolvedUrl;

    let additional = {};
    if (!isLogin && privateLinks.includes(pathname)) {
      additional = {
        redirect: {
          permanent: false,
          destination: `/auth?from=${context.resolvedUrl}`,
        },
      };
    }

    return {
      isLogin: !!isLogin,
      additional,
    };
  }

  logout() {
    AuthApi.logout().finally(() => {
      if (true) {
        cookie.deleteCookie(BROWSER_TOKEN_KEY);
        cookie.deleteCookie(BROWSER_USER_PHONE);
        cookie.deleteCookie('userData');
        localStorage.removeItem('isNewUser');
        window.location.replace('/');
      }
    });
  }
}

const auth_service = new Auth();

export default auth_service;
