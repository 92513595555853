import { PageAble } from "@/Types/CommonTypes";
import apiService from "../ApiService";
import { IMinUser, IUser, IUserSms } from "@/Models/User/IUser";

async function getMe() {
  return await apiService
    .setUrl(`/users/me`)
    .setMethod("GET")
    .request<IUser>();
};

async function logoutUser(body: { user: IUser }) {
  return await apiService
    .setUrl(`/users/logout`)
    .setBody(body)
    .setMethod("POST")
    .request<{ user: IUser }>();
};

async function getAllUsers() {
  return await apiService
    .setUrl(`/users`)
    .setMethod("GET")
    .setParams({ page: 0, size: 100000 })
    .request<PageAble<IMinUser>>();
};

async function getUserById(id: number) {
  return await apiService
    .setUrl(`/users/${id}`)
    .setMethod("GET")
    .request<IMinUser>();
};

async function getUserSms(params: { phone: number; hash?: string; }) {
  return await apiService
    .setUrl(`/users/sms`)
    .setMethod("GET")
    .setParams(params)
    .request<IUserSms>();
};

async function postUserSms(params: { phone: number | string; hash?: string; }) {
  return await apiService
    .setUrl(`/users/send-sms`)
    .setMethod("POST")
    .setParams(params)
    .request<IUserSms>();
};
async function deleteUserSms(params: { phone: number | string; otp: number | string; }) {
  return await apiService
    .setUrl(`/users/delete-by-sms-code`)
    .setMethod("DELETE")
    .setParams(params)
    .request<IUserSms>();
};

async function updateUser(body: IUser) {
  return await apiService
    .setUrl(`/users`)
    .setBody(body)
    .setMethod("PUT")
    .request<IUser>();
};

async function updateUserPhone(body: { phone: string; otp: string; }) {
  return await apiService
    .setUrl(`/users/phone`)
    .setBody(body)
    .setMethod("PUT")
    .request<boolean>();
};

async function uploadImg(formData: FormData) {
  return await apiService
    .setUrl(`/photos`)
    .setContentType("multipart/form-data")
    .setBody(formData)
    .setMethod("POST")
    .request();
};
async function deleteImg(id: string | null) {
  return await apiService
    .setUrl(`/photos/${id}`)
    .setMethod("DELETE")
    .request();
};

async function deleteUser() {
  return await apiService
    .setUrl(`/users`)
    .setMethod("DELETE")
    .request<IUser>();
};

const UserApi = {
  getMe,
  getUserById,
  logoutUser,
  getUserSms,
  postUserSms,
  deleteUserSms,
  updateUser,
  uploadImg,
  deleteImg,
  deleteUser,
  getAllUsers
}

export {
  getMe,
  getUserById,
  logoutUser,
  getUserSms,
  postUserSms,
  deleteUserSms,
  updateUser,
  uploadImg,
  deleteImg,
  deleteUser
};

export default UserApi;
