import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const EyeSvg: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.88 5.30764C16.7067 6.29417 16.7067 7.70649 15.88 8.69301C14.4366 10.4155 11.8947 12.8337 9 12.8337C6.10525 12.8337 3.5634 10.4155 2.12001 8.69301C1.29333 7.70649 1.29333 6.29417 2.12002 5.30764C3.5634 3.58518 6.10525 1.16699 9 1.16699C11.8947 1.16699 14.4366 3.58518 15.88 5.30764Z'
        stroke='#9DA0A9'
        strokeWidth='2'
      />
      <path
        d='M11.5 7.00033C11.5 8.38104 10.3807 9.50033 9 9.50033C7.61929 9.50033 6.5 8.38104 6.5 7.00033C6.5 5.61961 7.61929 4.50033 9 4.50033C10.3807 4.50033 11.5 5.61961 11.5 7.00033Z'
        stroke='#9DA0A9'
        strokeWidth='2'
      />
    </svg>
  );
};

export default EyeSvg;
