import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const AngleRight: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.40029 4.87982L1.81029 0.289816C1.62293 0.103565 1.36948 -0.000976562 1.10529 -0.000976562C0.841105 -0.000976562 0.587653 0.103565 0.40029 0.289816C0.306562 0.382779 0.232168 0.49338 0.181399 0.615239C0.130631 0.737098 0.104492 0.867804 0.104492 0.999816C0.104492 1.13183 0.130631 1.26253 0.181399 1.38439C0.232168 1.50625 0.306562 1.61685 0.40029 1.70982L5.00029 6.28982C5.09402 6.38278 5.16841 6.49338 5.21918 6.61524C5.26995 6.7371 5.29609 6.86781 5.29609 6.99982C5.29609 7.13183 5.26995 7.26253 5.21918 7.38439C5.16841 7.50625 5.09402 7.61685 5.00029 7.70982L0.40029 12.2898C0.211987 12.4768 0.105672 12.7309 0.104734 12.9963C0.103797 13.2616 0.208314 13.5165 0.395291 13.7048C0.582269 13.8931 0.836391 13.9994 1.10176 14.0004C1.36712 14.0013 1.62199 13.8968 1.81029 13.7098L6.40029 9.11982C6.96209 8.55732 7.27765 7.79482 7.27765 6.99982C7.27765 6.20481 6.96209 5.44232 6.40029 4.87982Z'
        fill='#fff'
      />
    </svg>
  );
};

export default AngleRight;
