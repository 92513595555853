import { deviceType } from "@/Page/SearchPage/Helpers/getPremiumCount";
import { FC, createContext } from "react";

export interface IIsSsrMobileValues {
  isMobile: boolean;
  isTablet: boolean;
  device: deviceType;
}

const IsSsrMobileContext = createContext<IIsSsrMobileValues>({
  isMobile: false,
  isTablet: false,
  device: 'computer'
});

interface IIsSsrMobileContextProvider {
  children: React.ReactNode;
  value: IIsSsrMobileValues;
}

const IsSsrMobileContextProvider: FC<IIsSsrMobileContextProvider> = ({ children, value }) => {

  return (
    <IsSsrMobileContext.Provider value={value}>
      { children }
    </IsSsrMobileContext.Provider>
  )
}


export {
  IsSsrMobileContext,
  IsSsrMobileContextProvider
}