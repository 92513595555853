// import dotenv from 'dotenv';
// dotenv.config();

type Key = string;
const SECRET_KEY: Key = process.env.SECRET_KEY || 'SECRET_KEY';

class EncrypingService {
  async encryptData(data: any) {
    return JSON.stringify(data);
  }

  async decryptData(encryptedData: string) {
    try {
      const value = JSON.parse(encryptedData);
      if (typeof value === 'object') {
        return value;
      } else {
        return value;
      }
    } catch (error) {
      return undefined;
    }
  }
}

const encrypingService = new EncrypingService();

export default encrypingService;
