import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { cookie } from '@/Services/BrowserStorage';
import auth_service from '../Auth';
import AuthApi from './Controllers/AuthApi';
import { logger } from '@/Services/Logger';

// TODO: axios-cache-adapter ni qo'shish kerak

const g = function (baseURL: string) {
  const client = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const refreshHandler = async (response: AxiosResponse) => {
    try {
      const originalRequest = response.config;
      // TODO refactor
      // @ts-ignore
      originalRequest._isRetried = true;

      const newToken = await AuthApi.refreshTokenWithAxios({
        phone: cookie.getCookie(BROWSER_USER_PHONE)!,
        token: cookie.getCookie(BROWSER_TOKEN_KEY)!,
      });
      if (newToken) {
        auth_service.login(newToken, cookie.getCookie(BROWSER_USER_PHONE)!);
        return await client.request(originalRequest);
      } else {
        return response;
      }
    } catch (syn_err) {
      logger.log('AXIOS ERROR HANDLER SYTNAX ERROR: ', syn_err);
      auth_service.logout();
      return response;
    }
  };

  client.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      if (config.url !== '/auth/login') {
        config.headers.authorization = config.headers.authorization || `Bearer ${cookie.getCookie(BROWSER_TOKEN_KEY)}`;
        // if(config.headers.authorization && config.headers.authorization.length > 10) {
        // } else {
        //   config.headers.authorization = `Bearer ${cookie.getCookie(
        //     BROWSER_TOKEN_KEY
        //   )}`;
        // }
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    async (response: AxiosResponse) => {
      // TODO refactor -
      // @ts-ignore
      // if(response.config.url?.includes('/users/me') && !response.data.id && !response.config._isRetried) {
      //   return refreshHandler(response);
      // } else {
      //   return response;
      // }

      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  return client;
};

const BROWSER_TOKEN_KEY = 'JOYLA_WEB_TOKEN';
const BROWSER_USER_PHONE = 'BROWSER_USER_PHONE';

export const BASE_URL = process.env.NEXT_PUBLIC_BACKEND_DOMAIN_URL;

// const client1 = g(`${BASE_URL}/api`);
// const client1 = g(`${BASE_URL}/mobile/api`);
const client1 = g(`${BASE_URL}/mobile/api`);

export { BROWSER_TOKEN_KEY, BROWSER_USER_PHONE };

export default client1;
