import { useCallback, useState, useEffect } from 'react';
import auth_service from '@/Services/Auth';
import AuthApi from '@/Services/Axios/Controllers/AuthApi';
import { IUser } from '@/Models/User/IUser';
import { cookie } from '@/Services/BrowserStorage';
import { BROWSER_USER_PHONE } from '@/Services/Axios';
import { getClientCoordinates } from '@/Services/Coordinates';
import UserApi from '@/Services/Axios/Controllers/UserApi';
import { firebaseConfig, firebase_instance } from '@/Services/Firebase';
import useLocalStorage from '@/Hooks/useLocalStorage';
import { useUserPreferencesState } from '@/Hooks/useUserPreferencesState';
import { useGlobalSiteSettingsContext } from '../GlobalSiteSettings/useGlobalSiteSettingsContext';
import { logger } from '@/Services/Logger';

const normalizeUserCoordsBeforeSetting = (data: IUser) => {
  if (!data) return data;

  const { user_lat, user_lang } = getClientCoordinates();

  return {
    ...data,
    lat: data.lat || user_lat,
    lang: data.lang || user_lang,
  };
};

// TODO agar user location datasi empty bo'lsa modal chiqazib tanlatish kerak
//  bo'lmasa getProducts qilganda backend error tashlaydi

export function useActions(initialValue: boolean) {
  const [language] = useLocalStorage<'uz' | 'ru'>('BROWSER_LANGUAGE', 'uz');
  const userInfo = useUserPreferencesState('userInfo');
  const changeUserPreference = useGlobalSiteSettingsContext(v => v.actions.changeUserPreference);
  const [userData, setUserInfoData] = useState<IUser | null>(() => {
    if (userInfo) {
      return userInfo;
    }

    if (typeof document !== 'undefined') {
      const data = cookie.getCookie<IUser>('userData');
      // @ts-ignore
      if (data) return JSON.parse(data);
      return { phone: '', firstname: '', lastname: '' } as IUser;
    } else {
      return { phone: '', firstname: '', lastname: '' } as IUser;
    }
  });
  const [isLogin, setIsLogin] = useState<boolean>(initialValue);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [requirePath, setRequirePath] = useState<string | null>(null);

  const login = useCallback((token: string, phone: string) => {
    auth_service.login(token, phone);
    setIsLogin(true);
  }, []);

  // NOTE cookies ga saqlab olish uchun yaratildi
  const setUserData = (value: IUser | null) => {
    changeUserPreference('userInfo', value);
    setUserInfoData(value);
  };

  const logout = useCallback(() => {
    auth_service.logout();
    setIsLogin(false);
  }, []);

  const showModal = useCallback((path?: string) => {
    setModalVisible(true);
    path && setRequirePath(path);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setRequirePath(null);
  }, []);

  const updateUserData = useCallback((data: IUser | null) => {
    setUserData(data);
    cookie.setCookie({ name: 'userData', value: JSON.stringify(data) });
  }, []);

  const fetchUserData = useCallback(async () => {
    try {
      const res = await AuthApi.getMe();
      if (res.data.id) {
        setUserData(res.data);
        cookie.setCookie({ name: 'userData', value: JSON.stringify(res.data) });
      }
    } catch (err) {
      logger.warn(err);
    }
  }, []);

  const getMe = useCallback(async () => {
    try {
      const res = await AuthApi.getMe();
      const saved_token = cookie.getCookie(BROWSER_USER_PHONE) as string;
      const saved_phone = cookie.getCookie(BROWSER_USER_PHONE) as string;

      if (res.data.id) {
        // TODO new user bo'lgandagi holatlarni ustida ishlash kerak
        const isNewUser = localStorage.getItem('isNewUser');
        if (isNewUser === 'true') {
          // TODO refactor
          let _userData = normalizeUserCoordsBeforeSetting(res.data);
          setUserData(_userData);
          cookie.setCookie({ name: 'userData', value: JSON.stringify(_userData) });

          // NOTE USER COORDSGA DEFAULT SET QILISH, refactor qilish kerak
          await UserApi.updateUser(_userData);
          localStorage.removeItem('isNewUser');
        } else {
          let _userData = normalizeUserCoordsBeforeSetting(res.data);
          setUserData(_userData);
          cookie.setCookie({ name: 'userData', value: JSON.stringify(_userData) });
        }
      } else if (saved_token && saved_phone) {
        const refresh_response = await AuthApi.refreshToken({ phone: saved_phone, token: saved_token });

        if (refresh_response.data.id_token) {
          auth_service.login(refresh_response.data.id_token, saved_phone);
        } else {
          // NOTE: if refresh fail
          logger.log('if refresh fail => logout');
          auth_service.logout();
        }
      } else {
        // NOTE: if phone or token is false value
        logger.log('if phone or token is false value => logout');

        auth_service.logout();
      }
    } catch (err) {
      logger.log('if occurred unexpected error => logout', err);
      // NOTE: if occurred unexpected error
      auth_service.logout();
    }
  }, []);

  useEffect(() => {
    // INIT FIREBASE
    firebase_instance.initialize({ language, ...firebaseConfig });

    if (isLogin) {
      getMe();
    }
  }, [isLogin]);

  return {
    state: {
      isLogin,
      modalVisible,
      userData,
      requirePath,
    },
    actions: {
      getMe,
      login,
      logout,
      showModal,
      closeModal,
      updateUserData,
      fetchUserData,
    },
  };
}

export type AuthContextType = ReturnType<typeof useActions>;
