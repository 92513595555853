import React, { FC } from "react";
import { ICommonSvgProps } from ".";

const AdPhone: FC<Omit<ICommonSvgProps, "name">> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6473 12.2197C14.6473 12.4597 14.594 12.7063 14.4806 12.9463C14.3673 13.1863 14.2207 13.413 14.0273 13.6263C13.7007 13.9863 13.3407 14.2463 12.934 14.413C12.534 14.5797 12.1007 14.6663 11.634 14.6663C10.954 14.6663 10.2273 14.5063 9.46065 14.1797C8.69398 13.853 7.92732 13.413 7.16732 12.8597C6.40065 12.2997 5.67398 11.6797 4.98065 10.993C4.29398 10.2997 3.67398 9.57301 3.12065 8.81301C2.57398 8.05301 2.13398 7.29301 1.81398 6.53967C1.49398 5.77967 1.33398 5.05301 1.33398 4.35967C1.33398 3.90634 1.41398 3.47301 1.57398 3.07301C1.73398 2.66634 1.98732 2.29301 2.34065 1.95967C2.76732 1.53967 3.23398 1.33301 3.72732 1.33301C3.91398 1.33301 4.10065 1.37301 4.26732 1.45301C4.44065 1.53301 4.59398 1.65301 4.71398 1.82634L6.26065 4.00634C6.38065 4.17301 6.46732 4.32634 6.52732 4.47301C6.58732 4.61301 6.62065 4.75301 6.62065 4.87967C6.62065 5.03967 6.57398 5.19967 6.48065 5.35301C6.39398 5.50634 6.26732 5.66634 6.10732 5.82634L5.60065 6.35301C5.52732 6.42634 5.49398 6.51301 5.49398 6.61967C5.49398 6.67301 5.50065 6.71967 5.51398 6.77301C5.53398 6.82634 5.55398 6.86634 5.56732 6.90634C5.68732 7.12634 5.89398 7.41301 6.18732 7.75967C6.48732 8.10634 6.80732 8.45967 7.15398 8.81301C7.51398 9.16634 7.86065 9.49301 8.21398 9.79301C8.56065 10.0863 8.84732 10.2863 9.07398 10.4063C9.10732 10.4197 9.14732 10.4397 9.19398 10.4597C9.24732 10.4797 9.30065 10.4863 9.36065 10.4863C9.47398 10.4863 9.56065 10.4463 9.63398 10.373L10.1407 9.87301C10.3073 9.70634 10.4673 9.57967 10.6207 9.49967C10.774 9.40634 10.9273 9.35967 11.094 9.35967C11.2207 9.35967 11.354 9.38634 11.5007 9.44634C11.6473 9.50634 11.8007 9.59301 11.9673 9.70634L14.174 11.273C14.3473 11.393 14.4673 11.533 14.5406 11.6997C14.6073 11.8663 14.6473 12.033 14.6473 12.2197Z"
        stroke="#818181"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default AdPhone;
