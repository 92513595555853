import { IKeyValue } from '@/Types/CommonTypes';
import { AxiosResponse, Method, ResponseType } from 'axios';
import client from './Config';

export enum ROLES {
  ROLE_USER = 'ROLE_USER',
  ROLE_NONE = 'ROLE_NONE',
}

class ApiService {
  public url: string = '';
  public body: any = '';
  public method: Method = 'GET';
  public signal: AbortSignal | undefined = undefined;
  public headers: IKeyValue = {};
  public params: IKeyValue = {};
  public responseType: ResponseType = 'json';
  public contentType: string = 'application/json';

  public setUrl(url: string): ApiService {
    this.url = url;
    return this;
  }

  public setBody<T>(body: T): ApiService {
    this.body = body;
    return this;
  }

  public setMethod(method: Method): ApiService {
    this.method = method;
    return this;
  }

  public setHeaders(headers: IKeyValue): ApiService {
    this.headers = headers;

    return this;
  }

  public setParams(params: IKeyValue): ApiService {
    this.params = params;
    return this;
  }

  public setSignal(signal?: AbortSignal): ApiService {
    this.signal = signal;
    return this;
  }

  public setResponseType(responseType: ResponseType) {
    this.responseType = responseType;
    return this;
  }

  public setContentType(type: string) {
    this.contentType = type;

    return this;
  }

  public async request<T = any>(): Promise<AxiosResponse<T>> {
    client.defaults.headers['Content-type'] = this.contentType;

    const req = client({
      method: this.method,
      url: this.url,
      data: this.body,
      headers: this.headers,
      params: this.params,
      signal: this.signal,
      // responseType:this.responseType
    });
    this.reset();

    const response: AxiosResponse<T> = await req;

    return response;
  }

  public resetParams(): ApiService {
    this.params = {};
    return this;
  }

  public reset() {
    this.url = '';
    this.body = '';
    this.method = 'GET';
    this.headers = {};
    this.params = {};
    this.responseType = 'json';
    this.contentType = 'application/json';
  }
}

const apiService = new ApiService();

export default apiService;
