import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const ShareSvg: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.0904 15.266C13.0314 15.5009 13 15.7468 13 16C13 17.6569 14.3431 19 16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.5964 13 13.4179 13.964 13.0904 15.266ZM13.0904 15.266L6.5738 11.5422M13.0904 4.73403C13.4179 6.03605 14.5964 7 16 7C17.6569 7 19 5.65685 19 4C19 2.34315 17.6569 1 16 1C14.3431 1 13 2.34315 13 4C13 4.25323 13.0314 4.49913 13.0904 4.73403ZM13.0904 4.73403L6.5738 8.45783M6.5738 11.5422C6.8444 11.0915 7 10.5639 7 10C7 9.43607 6.8444 8.90848 6.5738 8.45783M6.5738 11.5422C6.04935 12.4156 5.09293 13 4 13C2.34315 13 1 11.6569 1 10C1 8.34315 2.34315 7 4 7C5.09293 7 6.04935 7.58443 6.5738 8.45783'
        stroke='#0C88C6'
        strokeWidth='2'
      />
    </svg>
  );
};

export default ShareSvg;
