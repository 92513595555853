import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const ChatsSvg: FC<Omit<ICommonSvgProps, 'name'>> = (props) => {
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g id="Frame 2608942/Iconly/Light/Property 34">
      <g id="Chat">
      <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M7.69494 13.3909C7.05018 13.3909 6.52637 12.8671 6.52637 12.2223C6.52637 11.5795 7.05018 11.0557 7.69494 11.0557C8.3397 11.0557 8.86351 11.5795 8.86351 12.2223C8.86351 12.8671 8.3397 13.3909 7.69494 13.3909ZM12.2378 13.3909C11.593 13.3909 11.0692 12.8671 11.0692 12.2223C11.0692 11.5795 11.593 11.0557 12.2378 11.0557C12.8826 11.0557 13.4064 11.5795 13.4064 12.2223C13.4064 12.8671 12.8826 13.3909 12.2378 13.3909ZM15.6122 12.2223C15.6122 12.8671 16.136 13.3909 16.7807 13.3909C17.4255 13.3909 17.9493 12.8671 17.9493 12.2223C17.9493 11.5795 17.4255 11.0557 16.7807 11.0557C16.136 11.0557 15.6122 11.5795 15.6122 12.2223Z" fill="currentColor"/>
      <path id="Stroke 7" fillRule="evenodd" clipRule="evenodd" d="M12.02 2C6.21 2 2 6.74612 2 12.015C2 13.6975 2.49 15.4291 3.35 17.0115C3.51 17.2729 3.53 17.6024 3.42 17.9139L2.75 20.1572C2.6 20.698 3.06 21.0976 3.57 20.9374L5.59 20.3375C6.14 20.1572 6.57 20.3866 7.08 20.698C8.54 21.5583 10.36 22 12 22C16.96 22 22 18.1642 22 11.985C22 6.65598 17.7 2 12.02 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      </g>
    </svg>
  )
}

export default ChatsSvg;