import React, { FC } from "react";
import { ICommonSvgProps } from ".";

const AdOffer: FC<Omit<ICommonSvgProps, "name">> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.78025 10.2005L5.80025 13.2205C7.04025 14.4605 9.05359 14.4605 10.3003 13.2205L13.2269 10.2939C14.4669 9.05385 14.4669 7.04052 13.2269 5.79385L10.2003 2.78052C9.56692 2.14719 8.69359 1.80719 7.80025 1.85385L4.46692 2.01385C3.13359 2.07385 2.07359 3.13385 2.00692 4.46052L1.84692 7.79385C1.80692 8.69385 2.14692 9.56719 2.78025 10.2005Z"
        stroke="#818181"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33268 8.00033C7.25316 8.00033 7.99935 7.25413 7.99935 6.33366C7.99935 5.41318 7.25316 4.66699 6.33268 4.66699C5.41221 4.66699 4.66602 5.41318 4.66602 6.33366C4.66602 7.25413 5.41221 8.00033 6.33268 8.00033Z"
        stroke="#818181"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AdOffer;
