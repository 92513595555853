// import { Inter } from 'next/font/google';
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { typeSizes, sizes } from './../sizes';
import { typeColors, colors } from '../colors';
import { typeBgColors, bg } from '../bg';


declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
    customTheme: {
      colors: typeColors,
      bg: typeBgColors,
      sizes: typeSizes
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    customTheme?: {
      colors: typeColors,
      bg: typeBgColors,
      sizes: typeSizes
    }
  }
}

// export const inter = Inter({
//   weight: ['300', '400', '500', '700'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Roboto', 'Arial', 'sans-serif'],
// });

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      light: colors.lightPrimary
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    // fontFamily: inter.style.fontFamily,
  },
  customTheme: {
    colors,
    bg,
    sizes
  }
});

export default theme;
