import { logger } from '@/Services/Logger';
import { useState } from 'react';

/** React hook for localStorage
 * From https://usehooks.com/useLocalStorage/
 */
export default function useLocalStorage<T>(key: string, initialValue: T): [T, (x: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      try {
        return item ? JSON.parse(item) : initialValue;
      } catch (err) {
        return item ?? initialValue;
      }
    } catch (error) {
      // If error also return initialValue
      // logger.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T): void => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      logger.log(error);
    }
  };

  return [storedValue, setValue];
}
