import { useMediaQuery } from 'react-responsive';
import { IsSsrMobileContext } from "@/Context/IsSsrMobile";
import { useContext } from "react";

export const useIsMobile = () => {
  const isMobileWidth =  useMediaQuery({ query: '(max-width: 992px)' });
  const isMobile = useContext(IsSsrMobileContext);
  const device = isMobile?.device || 'computer';

  return device === 'mobile' || device === 'tablet' || isMobileWidth;
};
