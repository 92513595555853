import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const LocationSvg: FC<Omit<ICommonSvgProps, 'name'>> = props => {
  return (
    <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.6654 8.33366C14.6654 13.3337 8.83203 18.3337 7.9987 18.3337C7.16536 18.3337 1.33203 13.3337 1.33203 8.33366C1.33203 4.65176 4.3168 1.66699 7.9987 1.66699C11.6806 1.66699 14.6654 4.65176 14.6654 8.33366Z'
        stroke='#9DA0A9'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.4987 8.33366C10.4987 9.71437 9.37941 10.8337 7.9987 10.8337C6.61799 10.8337 5.4987 9.71437 5.4987 8.33366C5.4987 6.95295 6.61799 5.83366 7.9987 5.83366C9.37941 5.83366 10.4987 6.95295 10.4987 8.33366Z'
        stroke='#9DA0A9'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default LocationSvg;
