import React, { FC } from 'react';
import { useActions } from './useActions';
import GlobalSiteSettingsContext from './Context';
import { IUserPreferences } from '@/Services/SsrService/getUserPreferences';

interface IGlobalSiteSettingsProviderProps {
  children: React.ReactNode;
  savedUserPreferences: IUserPreferences;
}

const GlobalSiteSettingsProvider: FC<IGlobalSiteSettingsProviderProps> = ({ children, savedUserPreferences }) => {
  const state = useActions(savedUserPreferences);

  return <GlobalSiteSettingsContext.Provider value={state}>{children}</GlobalSiteSettingsContext.Provider>;
};

export default GlobalSiteSettingsProvider;
