import React, { FC } from 'react';
import { ICommonSvgProps } from '.';

const GridSvg: FC<Omit<ICommonSvgProps, 'name'>> = (props) => {
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M18.3333 9.08366V3.41699C18.3333 2.16699 17.8 1.66699 16.475 1.66699H13.1083C11.7833 1.66699 11.25 2.16699 11.25 3.41699V9.08366C11.25 10.3337 11.7833 10.8337 13.1083 10.8337H16.475C17.8 10.8337 18.3333 10.3337 18.3333 9.08366Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.3333 16.584V15.084C18.3333 13.834 17.8 13.334 16.475 13.334H13.1083C11.7833 13.334 11.25 13.834 11.25 15.084V16.584C11.25 17.834 11.7833 18.334 13.1083 18.334H16.475C17.8 18.334 18.3333 17.834 18.3333 16.584Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.74984 10.917V16.5837C8.74984 17.8337 8.2165 18.3337 6.8915 18.3337H3.52484C2.19984 18.3337 1.6665 17.8337 1.6665 16.5837V10.917C1.6665 9.66699 2.19984 9.16699 3.52484 9.16699H6.8915C8.2165 9.16699 8.74984 9.66699 8.74984 10.917Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.74984 3.41699V4.91699C8.74984 6.16699 8.2165 6.66699 6.8915 6.66699H3.52484C2.19984 6.66699 1.6665 6.16699 1.6665 4.91699V3.41699C1.6665 2.16699 2.19984 1.66699 3.52484 1.66699H6.8915C8.2165 1.66699 8.74984 2.16699 8.74984 3.41699Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default GridSvg;