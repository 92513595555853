
export const privateLinks = [
  '/profile',
  '/profile/wallet',
  '/profile/chats',
  '/profile/my_posts',
  '/profile/notifications',
  '/profile/user_settings',
  '/profile/language',
  '/offers',

  // TODO regexp ga match qilishini tekshirish kerak
  '/new_chat',
  '/chat',

  '/favorites',
  '/create',
  '/edit_product',
];
